import React from 'react';
import {Helmet} from "react-helmet";
import LayoutTmpl from "../components/layout";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Links from '@material-ui/core/Link';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));

const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));

const Rules = () => {

    return (
        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>Правила написания отзывов</title>
                <meta name="description" content="Правила написания отзывов"/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        <StyledPaper>
                            <Typography gutterBottom component="h1" variant="h5" color="textPrimary">
                                Правила написания отзывов
                            </Typography>

                            <ol>
                                <li><Typography variant="body2" gutterBottom>Рекомендуется писать отзывы с содержанием
                                    не менее 200 знаков.</Typography></li>
                                <li><Typography variant="body2" gutterBottom>Если отзыв пишется на товар, то желательно
                                    наличие сделанной вами лично фотографии.</Typography></li>
                                <li><Typography variant="body2" gutterBottom>Отзыв абсолютно исключает ненормативную
                                    лексику, бранные&nbsp;слова, месть на основе личной неприязни. Любое ваше мнение,
                                    пусть даже крайне негативное, необходимо писать в дипломатическом
                                    ключе.</Typography></li>
                                <li><Typography variant="body2" gutterBottom>Ваш отзыв&nbsp;обязательно
                                    должен&nbsp;отображать ваше личное мнение, с местоимениями «я, меня и т.
                                    д.».</Typography></li>
                                <li><Typography variant="body2" gutterBottom>Любое копирование и размещение отзывов с
                                    других сайтов категорически запрещено.</Typography></li>
                                <li><Typography variant="body2" gutterBottom>Отзывы, нарушающие правила, будут удалены,
                                    а аккаунт пользователя может быть заблокирован.</Typography></li>
                            </ol>
                            <Typography variant="body2" gutterBottom>
                                <Links href="/feedback">Остались вопросы?</Links>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Links href="/offer">Пользовательское соглашение</Links>
                            </Typography>

                        </StyledPaper>

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    );

}

Rules.propTypes = {
    window: PropTypes.func,
};

export default Rules